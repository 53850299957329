@import "minima";

.site-description {
	line-height: 58px;
	padding-left: 1em;
	color: #777;
}

.inline-menu {
  display: inline-block;
	list-style: none;
}

.inline-menu li {
	display: inline-block;
	padding: 1em;
}

.paginator {
	width: 100%;
	list-style: none;
	margin: 0px;
	vertical-align: middle;
	height: 54px;
}

.paginator li {
	width: 45%;
	display: inline-block;
	padding: 1em;
	background: #e8e8e8;
	vertical-align: middle;
	white-space: nowrap;
	overflow: hidden;
  text-overflow: ellipsis;
}

.paginator .older {
	float: left;
	text-align: left;
}

.paginator .older a::before {
	content: "← "
}

.paginator .newer {
	float: right;
	text-align: right;
}

.paginator .newer a::after {
	content: " →"
}

hr {
	border: 0;
	border-top: 1px solid #e8e8e8;
}

footer {
	text-align: center;
}

.social-media-list {
	text-align: center;
}

.social-media-list li {
	display: inline-block;
	padding: 5px;
}

time.dt-edited {
	padding-left: 1em;
}

.post-tags {
	line-height: 1em;
	display: inline-block;
	padding-left: 1em;
}

.tag-list li {
	display: inline-block;
	padding-right: 1em;
}

.related-content {
	overflow: scroll;
	overflow-y: hidden;
	vertical-align: top;
	white-space: nowrap;
}

.related-post {
	display: inline-block;
	width: 5em;
	vertical-align: top;
	height: 6em;
	padding: 0.4em;
	background: #e8e8e8;
	overflow: hidden;
  text-overflow: ellipsis;
  
	white-space: normal;
}
.related-post a {
	vertical-align: top;
}

.breadcrumb {
	margin-left: 0;
	list-style: none;
	height: 2em;
	background: #e8e8e8;
}

.breadcrumb li {
	display: inline-block;
	vertical-align: middle;
}

.breadcrumb li:first-child {
	padding-left: 1em;
}

.breadcrumb li * {
	vertical-align: middle;
}

.breadcrumb li::after { 
	padding-left: 1em;
	color: white;
  content: "〉"; 
  display: inline-block; 
  vertical-align: middle;
}

.breadcrumb li:last-child::after {
	content: "";
}

.bordered {
	border: 1px solid #e8e8e8;
	padding: 1em;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	border-radius: 0.5em;
}

.cv-list {
  margin-left: 0;
  list-style: none;
}

.cv-item {
	margin-left: 0;
	margin-bottom: 1em;
	list-style: none;
}

.cv-item h3 {
	margin: 0;
}

.cv-item *:last-child {
	margin-bottom: 0;
}

.presentation-deliveries {
	padding: 0;
	margin: 0;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	padding-left: 0.5em;
	list-style: none;
	background: #e8e8e8;
}

.presentation-deliveries > li {
	display: inline-block;
	padding-right: 1em;
}